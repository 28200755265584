
.parallax {
  position: relative;
  height: 100vh; /* Adjust as needed */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ... existing code ... */

.title-overlay {
  /* transform: translateX(-50%); */
  position: absolute;
  /* left: 50%; */
  top: 50%;
  z-index: 1;
  color: white;
  text-align: center;
  width: -moz-fit-content;
  /* width: 100%; */
  
}

.spline-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 20px; */

  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  /* border: 1px solid rgba(255, 255, 255, 0.3);  */
  padding: 10px 20px;
}

.logo {
  font-family: "Montserrat", sans-serif;
  color: #f0f0f0; /* Light color to stand out against black background */
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  margin-top: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 
               4px 4px 8px rgba(0, 0, 0, 0.3),
               6px 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for more depth */
  transform: perspective(500px) translateZ(10px);
}


.title-text{
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 70px;
  text-align:center;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 
  4px 4px 8px rgba(0, 0, 0, 0.3),
  6px 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for more depth */
transform: perspective(500px) translateZ(10px);
color: #fff;
}
.description-text{
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 
  4px 4px 8px rgba(0, 0, 0, 0.3),
  6px 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for more depth */
transform: perspective(500px) translateZ(10px);
color: #fff;
}
/* ... existing code ... */


/* Import Anton font */


/* ... existing code ... */

.title-overlay h1,
.title-overlay h3 {
  font-family: "Montserrat", sans-serif;
}


.title-text {
  font-family: "Montserrat", sans-serif;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pd-title{
  margin-left:'100px' !important;
  margin-right:'1000px' !important;
}
.design-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 20px;
}
.design-text{
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  width: 50%;
}

.worldContainer{
  display: 'grid' !important;
  grid-template-columns: 1fr 1fr !important;
  align-items:center !important;
}
.our-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 20px;
}
.our-desc{
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.mobile-heading{
  display: none;
}

.videocontiner{
  margin-top: 100 !important;
  margin-bottom:150 !important;
}
@media only screen and (max-width: 620px) {

  .design-title{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .design-text{
    font-size: 14px;
    font-weight: 400;
    width: 90%;
  }
  .mobile-size{
    margin-left: 65px;
  }
  .title-overlay {
    display:none;
  }
  .mobile-heading{
    display:block;
    margin-top: 50;
  }

}

